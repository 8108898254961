import * as React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Logo from "../../assets/worket-logo.svg";

interface HomeScreenProps {
    
}

interface HomeScreenState {
    
}

class HomeScreen extends React.Component<HomeScreenProps, HomeScreenState> {
    render() { 
        return (
            <div className='home container text-center pt-5'>
                <img width={350} src={Logo} alt="Worker" />

                <Row >
                    <Col  md={{ offset: 3, span: 6}}>
                        <Alert variant='info' className='mt-5'>
                            Falta ingresar el parametro en la url
                        </Alert>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HomeScreen;