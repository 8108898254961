// Generated by https://quicktype.io

export interface iResult {
    companyName:     CompanyName;
    experienceRange: number[];
    languages:       any[];
    professions:     string[];
    skillsReq:       SkillsReq[];
    title:           string;
    geolocations:    any[];
    description:     string;
    jobType:         JobType;
    jobPayment:      JobPayment;
    salaryRange:     any[];
    team:            null | string;
    idCompany:       string;
    id:              string;
    companyLogo?:    string;
    image?:          string;
    video?:          string;
}

export enum CompanyName {
    CEDITottus = "CEDI Tottus",
    Tottus = "Tottus",
}

export enum JobPayment {
    Effective = "effective",
}

export enum JobType {
    'fulltime' = "Jornada completa",
    'parttime' = "Media jornada",
	'hourly' = "Jornada por hora",
	'remote' = "Trabajo remoto",
	'young' = "Jóvenes profesionales",
	'internship' = "Pasantía",
}

export enum SkillsReq {
    ServicioDeAtenciónAlCliente = "Servicio de atención al cliente",
    Ventas = "Ventas",
}
