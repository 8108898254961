import React from 'react';
import "./index.scss"
import { Card } from 'react-bootstrap';
import { iResult, JobType } from '../../interfaces/results';
import API from '../../services/api';
import { DEFAULT_IMG } from '../../constants';

interface CardResultProps {
    result: iResult;

    background: string;

    color: string;
}

interface CardResultState {
    
}



const ItemJobType = ( props: { jobType: string, background:string,color:string }) => {
    // const JobTypeName = JobType[props.jobType] || JobType.fulltime;

    let jobTypeName = '';
    switch (props.jobType) {
        case 'fulltime':
            jobTypeName = JobType.fulltime;
            break;
        case 'parttime':
            jobTypeName = JobType.parttime;
            break;
        case 'internship':
            jobTypeName = JobType.internship;
            break;
        case 'hourly':
            jobTypeName = JobType.hourly;
            break;
        case 'remote':
            jobTypeName = JobType.remote;
            break;
        case 'young':
            jobTypeName = JobType.young;
            break;
    }

    // const jobTypeName = props.jobType === JobType.fulltime ? JobType.fulltime : JobType.parttime;
    return (
        <Card.Text style={{backgroundColor:props.background, color:props.color, border: `1px solid ${props.background}`}} className='results-tag'>
            <span>{jobTypeName}</span>
        </Card.Text>
    )
}

export default class CardResult extends React.Component<CardResultProps, CardResultState> {

    componentDidMount(): void {
        this.scrollTop();
    }

    api = new API();

    CardVideo = ( props: {src: string} ) => {
        return (
            <div className="video-section">
                <video 
                    onMouseOver={ (event: any) => event.target.play() } 
                    onMouseOut={ (event: any) => event.target.pause() } 
                    style={{maxWidth:"100%"}}>
                        <source src={props.src} type="video/mp4"></source>
                </video>
            </div>
        )
    }

    scrollTop = () => {
        window.scrollTo(0, 0);
    }

    getColorBorderTop = () => {
        return `4px solid ${this.props.background}`;
    }

    OnLoadImage = (event: any) => {

    }

    render() { 

        const { result } = this.props;

        return (
            <Card className='card-company' style={{borderTop:this.getColorBorderTop()}}>
                <a href={ this.api.getUrlLink( result.id ) }>

                    <Card.Body style={{color:this.props.background}}>
                        <Card.Title>{result.title}</Card.Title>
                        <ItemJobType jobType={result.jobType} background={this.props.background} color={this.props.color}/>
                    </Card.Body>

                    <div className="card-img-container">
                        {
                            result.image && ( <Card.Img variant='top' src={result.image} alt={result.title} /> )
                        }
                        {/* {result.video && ! result.image && ( <this.CardVideo src={result.video} /> )} */}
                        {/* If not has video and image.. */}
                        {
                            !result.image && ( <Card.Img variant='top' src={ DEFAULT_IMG } alt={result.title} /> )
                        }
                        {/* <Card.ImgOverlay>
                            <Card.Text>{result.companyName}</Card.Text>
                        </Card.ImgOverlay> */}
                    </div>
                </a>
            </Card>
        );
    }
}