import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { DEFAULT_LANG } from "../constants"
import esAR from "./es-AR"

const resources = {
    "es-AR": esAR,
}

i18n.use(initReactI18next).init({
    resources,
    lng: DEFAULT_LANG,
    interpolation: {
        escapeValue: false,
    }
})

export default i18n