export interface ValidatorError {
    [key: string]: boolean
}

export type ValidatorsFn = (value: any) => ValidatorError | null;

export type AsyncValidatorFn = (value: any) => Promise<ValidatorError | null>;

export class Validators {

    static email = (value: any): ValidatorError | null => {
        if(value === null || value === undefined) return null
        if(typeof value !== 'string') value = value.toString();
        //eslint-disable-next-line
        const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        return re.test(value) ? null : { email: true };
    }

    static required = (value: any): ValidatorError | null => {
        if(value === null || value === undefined) return { required: true };
        if(typeof value === 'string') return value.length > 0 ? null : { required: true };
        if(typeof value === 'number' || typeof value === 'bigint') return value.toString().length > 0 ? null : { required: true };
        if(typeof value === 'object') return Object.keys(value).length > 0 ? null : { required: true };
        if(Array.isArray(value)) return value.length > 0 ? null : { required: true };
        return null;
    }
}