import * as React from 'react';
import "./index.scss";

interface HeaderProps {
    name: string;

    logo: string;

    slogan ?: string;
}

interface HeaderState {
    
}

class Header extends React.Component<HeaderProps, HeaderState> {
    render() { 
        const { name, logo, slogan } = this.props

        return (
            <>
                <header className='company-header'>
                    <div className="container">
                        <div className='company-header-logo'>
                            <img src={logo} alt={name} />
                        </div>
                    </div>
                </header>
                {
                    slogan && (
                        <section className='company-slogan'>
                            <div className="container mb-5">
                                <h2>{slogan}</h2>
                            </div>
                        </section>
                    )
                }
            </>
        );
    }
}

export default Header;