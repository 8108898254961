import React from "react";
import { InputGroup, Row } from "react-bootstrap";
import { FormControl } from "../../../../containers/form/common";
import Input from "../../../../containers/form/input";
import './index.scss';

interface SearchComponentProps {
    search: (search: string) => void;

    background: string;

    color: string;
}

interface SearchComponentState {
    search: FormControl;
}

class SearchComponent extends React.Component<SearchComponentProps, SearchComponentState> {

    state: SearchComponentState = {
        search: new FormControl('')
    }

    onSubmit() {
        this.props.search(this.state.search.value);
    }

    onChange(value: string) {
        this.onSubmit();
    }

    render() {
        return (
            <section className="container">
                {/* link goBack .. d-flex justify-content-between mb-4-5 */}
                <Row>
                    <div className='search'>
                        <a style={{backgroundColor:this.props.background, color:this.props.color}} className="btn icon-arrow" href="./">⬅ &nbsp;Atrás</a>

                        <InputGroup className="search-input-container">
                            <Input className="form-control search-input" type="search" onChange={this.onChange.bind(this)} control={this.state.search} name="search" placeholder="Buscar..." />
                            <span className="search-input-preffix">
                                <i aria-label="icon: search">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                    </svg>
                                </i>
                            </span>
                        </InputGroup>
                    </div>
                </Row>

            </section>
        );
    }
}

export default SearchComponent;