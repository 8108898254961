import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { iSession } from './interfaces/session';
import Screens from './screens';
import { RootState } from './store';
import { setResults } from './store/session/actions';

interface StoreProps {
  Session: iSession;
}

interface DispatchProps {
  setResults: ( url_remote: string) => void;
}

type Props = StoreProps & DispatchProps;

class App extends React.Component<Props> {

  // componentDidMount(): void {
  //   this.props.setResults('dfgfddfg');
  // }

  render() {
    return (
      <div className="app">
        <BrowserRouter>
          <Screens />
        </BrowserRouter>
      </div>
    )
  }
}

// export default App;

const storeToProps = (state: RootState) => {
  return {
    Session: state.session
  }
}

const dispatchToProps = {
  setResults
};

export default connect(storeToProps, dispatchToProps)(App);
