import React from "react";
import { Route, Routes } from "react-router-dom";
import Paths from "../services/paths";
import HomeScreen from "./home";
import CompanyScreen from "./company";
import ResultScreen from "./result";
import NotFoundScreen from "./not-found";


class Screens extends React.Component {
    render() {
        return (
            <Routes>
                <Route path={Paths.Home} element={<HomeScreen />} />
                <Route path={Paths.Company} element={<CompanyScreen  />} />
                <Route path={Paths.CompanyResult} element={<ResultScreen />} />
                <Route path="*" element={<NotFoundScreen />} />
            </Routes>
        )
    }
}

export default Screens