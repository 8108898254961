import * as React from 'react';
import "./index.scss"
import { Card } from 'react-bootstrap';
import { iCompanySubgroup } from '../../interfaces/company';
import { Link } from 'react-router-dom';
import Paths from '../../services/paths';

interface CardCompanyProps {
    slug: string;

    subGroup: iCompanySubgroup;

    background: string;

    color: string;
}

interface CardCompanyState {
    
}

class CardCompany extends React.Component<CardCompanyProps, CardCompanyState> {
    render() { 

        const { background, color, subGroup, slug } = this.props;

        return (
            <Card className='card-company'>
                <Link to={Paths.GO.CompanyResult(slug, subGroup.id_team)}>
                    <div className="card-img-container">
                        <Card.Img variant='top' src={subGroup.image} alt={subGroup.title} />
                        <Card.ImgOverlay>
                            <Card.Text>📍{subGroup.location}</Card.Text>
                            <Card.Text>{subGroup.address}</Card.Text>
                        </Card.ImgOverlay>
                    </div>
                    <Card.Body style={{ background, color }}>
                        <Card.Title>{subGroup.title}</Card.Title>
                        <Card.Text>{subGroup.description}</Card.Text>
                    </Card.Body>
                </Link>
            </Card>
        );
    }
}

export default CardCompany;