import * as React from 'react';
import { connect } from 'react-redux';
import { COMPANIES } from '../../constants';
import CardResult from '../../containers/card-result';
import Header from '../../containers/header';
import { WithRouter, withRouter } from '../../helpers/route';
import { iCompany } from '../../interfaces/company';
import { iResult } from '../../interfaces/results';
import API from '../../services/api';
import { Col, Row } from 'react-bootstrap';
import { iSession } from '../../interfaces/session';
import { RootState } from '../../store';
import { setResults } from '../../store/session/actions';
import SearchComponent from './components/search';
import MetadataService from '../../services/metadata';

// interface ResultScreenProps extends WithRouter { }
interface ResultScreenProps extends WithRouter {
    setResults: (url_remote: string) => void;
}
interface StoreProps {
    Session: iSession;
}
type Props = ResultScreenProps & StoreProps;

interface ResultScreenState {
    company: iCompany | null;
    slug: null | string;
    id: null | string;
    results: iResult[];
    auxResults: iResult[];
}


class ResultScreen extends React.Component<Props, ResultScreenState> {

    state: ResultScreenState = {
        slug: null,
        id: null,
        company: null,
        results: [],
        auxResults: []
    }

    async componentDidMount(): Promise<void> {
        const { slug, id } = this.props.params;
        const company = COMPANIES.find(company => company.key === slug) as iCompany;

        MetadataService.setTitle(company.title);
        MetadataService.setMetaDescription(company.description);
        MetadataService.setFavicon(company.favicon);
        MetadataService.setThemeColor( company.bg_color );
        
        if( this.props.Session.results.length === 0 ){
            await this.props.setResults( company.request_url );
        }

        const resultsFiltered = this.props.Session.results.filter( item => item.team === id );
        this.setState({ slug, id, company, results: resultsFiltered, auxResults: resultsFiltered });
        
    }

    getData = ( request_url: string ) => {
        const api = new API();
        api.getPostulants( request_url ).then( results => {

            this.setState({ results });
        }).catch(err => {

        })
    }

    search(params: any) {
        const newRegex = new RegExp(params, 'i');
        const tmpData = this.state.results.filter(item => item.title.match(newRegex)) || [];
        this.setState({ auxResults: tmpData });
    }

    spinLoader() {
        return (
            <span className="loader"></span>
        );
    }

    cardWithResults(index:number,item:any){
            return (
                <Col lg={4} md={6} key={index} >
                    <CardResult key={index} result={item} background={ `${this.state.company?.bg_color}` } color={ `${this.state.company?.text_color}` }  />
                </Col>
            )
    }

    render() { 
        if (!this.state.company) {
            return <div className='container'>
                <div className='row text-center'>
                    { this.spinLoader() }
                </div>
            </div>;
        }
        return (
            <div>
                <Header name={ `${this.state.company?.company}` } logo={`${this.state.company?.logo}`} />
                <div style={{ background: '#fbfcff', padding: '3rem 0 5rem'}}>
                    <SearchComponent search={ this.search.bind(this)} background={ `${this.state.company?.bg_color}`} color={ `${this.state.company?.text_color}`} />
                    <section className="container">
                        <Row className='g-4'>
                            {
                                this.state.auxResults.length > 0 ? 
                                this.state.auxResults.map( (item, index) => {
                                    return this.cardWithResults(index,item) 
                                } )
                                : this.spinLoader()
                            }
                        </Row>
                    </section>
                </div>
            </div>
        );
    }
}

// export default withRouter(ResultScreen);

const stateToProps = (state: RootState) => {
    return {
        Session: state.session
    }
}

const dispatchToProps = { setResults };

export default connect(stateToProps, dispatchToProps)(withRouter(ResultScreen));