import * as React from 'react';
import "./index.scss";
import { Button, Container } from 'react-bootstrap';
import { iCompanySubgroup } from '../../interfaces/company';

interface FilterProps {
    subGroups: iCompanySubgroup[];

    onFilter?: (subGroup: iCompanySubgroup[]) => void;
}

interface FilterState {
    list: string[];
    select: string;
}

class Filter extends React.Component<FilterProps, FilterState> {

    readonly state: FilterState = {
        list: ['All'],
        select: 'All'
    }

    componentDidMount(): void {
        const { subGroups } = this.props;
        const list = ['All'];
        subGroups.forEach(item => {
            if(list.includes(item.filter) || !item.filter) return;
            list.push(item.filter);
        })
        this.setState({ list })
    }

    render() { 
        const { list, select } = this.state;
        if (list.length === 1) return null

        return (
            <Container className="filter-buttons mb-3 text-center">
                {
                    list.map((item, index) => {
                        return (
                            <Button key={index} variant='outline-dark' className={item === select ? 'active' : ''} onClick={() => this.handleSelect(item)}>
                                {item}
                            </Button>
                        )
                    })
                }
            </Container>
        );
    }

    private handleSelect = (selected: string) => {
        this.setState({ select: selected });

        const { subGroups, onFilter } = this.props;

        const filter = subGroups.filter(subGroup => {
            if(selected === 'All') return true;
            return subGroup.filter === selected;
        })

        if(onFilter) onFilter(filter);
    }
}

export default Filter;