import axios from 'axios';
import { LINK_URL } from '../constants';
import { iCompany } from '../interfaces/company';
import { iResult } from '../interfaces/results';

// type slugs = 'precio-uno' | 'tottus';

export default class API {

    // constructor() {}

    getPostulants( request_url: string | null ) {
        return new Promise<iResult[]>((resolve, reject) => {
            
            if( ! request_url ) return resolve([]);

            axios.get( request_url )
                .then( response => {
                    resolve( response.data );
                })
                .catch( error => {
                    reject( error )
                })
        })
    }
    

    async getCompanies() {
        return new Promise<iCompany[]>((resolve, reject) => {
            fetch("https://empleos.worcket.com/static/companies.json")
                .then(response => response)
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
        })
    }


    getUrlLink = ( id: string ) => `${LINK_URL}${id}`;

}