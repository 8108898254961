
class MetadataService {
    static setTitle(title: string) {
        document.title = title;
    }

    static setFavicon(url: string) {
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = url;
    }

    static setMetaDescription(description: string) {
        let meta = document.querySelector('meta[name="description"]') as HTMLMetaElement;
        if (!meta) {
            meta = document.createElement('meta');
            meta.name = 'description';
            document.head.appendChild(meta);
        }
        meta.content = description;
    }

    static setMetaKeywords(keywords: string) {
        let meta = document.querySelector('meta[name="keywords"]') as HTMLMetaElement;
        if (!meta) {
            meta = document.createElement('meta');
            meta.name = 'keywords';
            document.head.appendChild(meta);
        }
        meta.content = keywords;
    }

    static setMetaAuthor(author: string) {
        let meta = document.querySelector('meta[name="author"]') as HTMLMetaElement;
        if (!meta) {
            meta = document.createElement('meta');
            meta.name = 'author';
            document.head.appendChild(meta);
        }
        meta.content = author;
    }

    static setMetaRobots(robots: string) {
        let meta = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
        if (!meta) {
            meta = document.createElement('meta');
            meta.name = 'robots';
            document.head.appendChild(meta);
        }
        meta.content = robots;
    }

    static setMetaViewport(viewport: string) {
        let meta = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
        if (!meta) {
            meta = document.createElement('meta');
            meta.name = 'viewport';
            document.head.appendChild(meta);
        }
        meta.content = viewport;
    }

    static setThemeColor(color: string) {
        let meta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement;
        if (!meta) {
            meta = document.createElement('meta');
            meta.name = 'theme-color';
            document.head.appendChild(meta);
        }
        meta.content = color;
    }

}

export default MetadataService;