import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { COMPANIES } from '../../constants';
import CardCompany from '../../containers/card-company';
import Filter from '../../containers/filter';
import Header from '../../containers/header';
import { WithRouter, withRouter } from '../../helpers/route';
import { iCompany, iCompanySubgroup } from '../../interfaces/company';
import { iSession } from '../../interfaces/session';
import MetadataService from '../../services/metadata';
import { RootState } from '../../store';
import { setResults } from '../../store/session/actions';

// interface CompanyScreenProps extends WithRouter {}

interface CompanyScreenProps extends WithRouter {
    setResults: (url_remote: string) => void;
}
interface StoreProps {
    Session: iSession;
}
type Props = CompanyScreenProps & StoreProps

interface CompanyScreenState {
    company: iCompany | null;
    subGroups: iCompanySubgroup[];
}

// CompanyScreenProps
class CompanyScreen extends React.Component<Props, CompanyScreenState> {

    readonly state: CompanyScreenState = {
        company: null,
        subGroups: []
    }

    async componentDidMount(): Promise<void> {
        const { slug } = this.props.params;
        
        const company = COMPANIES.find((company:any) => company.key === slug) as iCompany;
        if(!company) return;

        MetadataService.setTitle(company.title);
        MetadataService.setMetaDescription(company.description);
        MetadataService.setFavicon(company.favicon);
        MetadataService.setThemeColor( company.bg_color );

        this.setState({ company, subGroups: company.subgroups });

        // Get results from API
        // this.props.params.setResults('sdfsfdaf')
        if( this.props.Session.results.length === 0 ) {
            await this.props.setResults( company.request_url );
        }
    }
    spinLoader() {
        return (
            <div className='w-100 h-100 d-flex'>
                <span className="loader"></span>
            </div>
        );
    }

    render() { 
        const { company, subGroups } = this.state;
        if (!company) return this.spinLoader();

        return ( 
            <div className='company'>
                <Header name={company.company} logo={company.logo} slogan={company.slogan} />
                <Filter subGroups={company.subgroups} onFilter={this.handleFilter.bind(this)}/>
                <section className='company-subgroup container'>
                    <Row className="g-4">
                        {
                            subGroups.map((subgroup, index) => {
                                return (
                                    <Col lg={4} md={6} key={index}>
                                        <CardCompany slug={company.key} subGroup={subgroup} background={company.bg_color} color={company.text_color} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </section>
            </div>
        );
    }

    private handleFilter = (subGroups: iCompanySubgroup[]) => {
        this.setState({ subGroups });
    }
}

// export default withRouter(CompanyScreen);

const stateToProps = (state: RootState) => {
    return {
        Session: state.session
    }
}

const dispatchToProps = { setResults };

export default connect(stateToProps, dispatchToProps)(withRouter(CompanyScreen));