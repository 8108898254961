import React from "react";
import "./index.scss";

class NotFoundScreen extends React.Component {
    render() {
        return (
            <div>404</div>
        )
    }
}

export default NotFoundScreen;