export default class Paths {

    static Home = "/";

    static Company = "/:slug";

    static CompanyResult = "/:slug/:id";

    static GO = {
        Home: () => Paths.Home,

        Company: (slug: string) => Paths.Company.replace(":slug", slug),

        CompanyResult: (slug: string, id: string) => Paths.CompanyResult.replace(":slug", slug).replace(":id", id)
    }

}