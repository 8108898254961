import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { iSession } from "../../interfaces/session";
import SESSION from "./state";


export const sSession = createSlice<iSession, SliceCaseReducers<iSession>, 'session'>({
    name: 'session',
    initialState: SESSION,
    reducers: {
        setInitWebSite: (store, action) => void(store.initWebSite = action.payload),
        _setResults: (store, action) => void(store.results = action.payload)
    }
})

export default sSession.reducer;
/** Exporto las acciones de redux */
export const { setInitWebSite, _setResults } = sSession.actions