import { Dispatch } from "@reduxjs/toolkit";
import { _setResults } from ".";
import API from "../../services/api";

export const setInitWebSite = () => (dispatch: Dispatch<any>) => {
    
}
// url_remote: string
export const setResults = ( url_remote:string ) => async (dispatch: Dispatch) => {
    try{
        const api = new API();
        const response = await api.getPostulants( url_remote);
        dispatch( _setResults( response ) );
    }catch(err){

    }
}